import React from "react";
import "./style.css";
const SalesManagement = () => {
  return (
    <div className="sales-management-wrapper-data" data-aos="fade-up"
    data-aos-offset="50"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-bottom">
      <img src="https://memate-website.s3.ap-southeast-2.amazonaws.com/assets/traker-iPhone-mockup.svg" alt="raker-iPhone" />
      <div className="content-wrapper">
        <p className="sale-title">Advanced</p>
        <p className="sales-heading-sales">Replace Clock-In<br/>Clock-Out </p>
        <p className="sales-type">Devices</p>
        <p className="sales-description-sales">
        meMate replaces all your traditional clock-in and<br/> clock-out time-tracking devices. With the meMate<br/> mobile 
        app, tracking shifts becomes simpler and <br/>more efficient, streamlining workforce time <br/>management in your office.
       </p>
      </div>
    </div>
  );
};

export default SalesManagement;
