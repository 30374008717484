import ComingSoon from '../../layout/coming-soon';

const MultiLocationComponent = () => {
  return (
    <>
     <ComingSoon />
    </>
  );
};

export default MultiLocationComponent;
