import ComingSoon from '../../layout/coming-soon';
const WatchDemoComponent = () => {
  return (
    <>
    <ComingSoon />
    </>
  );
};

export default WatchDemoComponent;
