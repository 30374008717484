import ComingSoon from '../../layout/coming-soon';

const TaskManagementComponent = () => {
  return (
    <>
  <ComingSoon />
    </>
  );
};

export default TaskManagementComponent;
