import ComingSoon from '../../layout/coming-soon';

const TimeSheetComponent = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default TimeSheetComponent;
